import { useWindowMessage, WindowMessageParams } from '@sortlist-frontend/utils';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { useNavigationContext } from '_core/context/navigation-context';
import { usePublicAppContext } from '_core/context/public-app-context';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';

import { NormalBriefingProps } from './types';

const NormalBriefing = dynamic<NormalBriefingProps>(
  () => import('_components/Briefing/versions/NormalBriefing').then((mod) => mod.NormalBriefing),
  {
    ssr: false,
  },
);
export type CTA = 'navbar-need' | 'navbar-advice' | 'navbar-project' | 'hero' | 'card-cta' | 'footer';
type Data = { cta?: CTA; expertiseId?: number; results?: string };

export const OPEN_BRIEFING = 'open-briefing';
export const FINISH_BRIEFING = 'finish-briefing';
export const CLOSE_BRIEFING = 'close-briefing';

type Action = typeof OPEN_BRIEFING | typeof CLOSE_BRIEFING | typeof FINISH_BRIEFING;

export const useBriefingMessage = (params?: WindowMessageParams<Action, Data>) =>
  useWindowMessage<Action, Data>(params);

export const BriefingDialog = () => {
  const [open, setOpen] = useState(false);
  const [cta, setCta] = useState<string | undefined>();
  const [expertise, setExpertise] = useState<number | undefined>();

  const { query } = useNavigationContext();

  const { domainInfo, briefingOptions, locale } = usePublicAppContext();
  const { expertise: expertiseOption, skills, placeId, address, iso31661, page } = briefingOptions || {};
  const currency = domainInfo?.getCurrency() || 'EUR';
  const defaultIso31661 = domainInfo?.getIso31661();
  const { placeId: defaultPlaceId, address: defaultAddress } = domainInfo?.getLocation(locale) ?? {};

  // Next doesn't handle regional locales yet.. The only one we handle at the moment is nl-BE.
  // To make sure the project are posted correctly and handled by the right person, we need this trick.
  const regionalizedLocale = domainInfo?.getIso31661() === 'BE' && locale === 'nl' ? 'nl-BE' : locale;

  const briefingProps = {
    page: page,
    cta: cta,
    address: address || defaultAddress,
    placeId: placeId || defaultPlaceId,
    expertise: expertise || expertiseOption,
    locale: regionalizedLocale || 'en',
    currency: currency || undefined,
    iso31661: iso31661 || defaultIso31661 || undefined,
    skills: skills,
    open: open,
    onClose: () => setOpen(false),
    queryParams: query,
  };

  const { sendMessage, sendMessageToParent } = useBriefingMessage({
    onReceiveMessage: (action, data) => {
      if (action === OPEN_BRIEFING) {
        setCta(data.cta);
        setExpertise(data.expertiseId != null ? Number(data?.expertiseId) : undefined);
        setOpen(true);
      }
      if (action === CLOSE_BRIEFING) {
        setOpen(false);
        setCta(undefined);
        setExpertise(undefined);
      }
    },
    sendToIframeId: 'directory-actions',
  });

  return open && cta != null ? (
    <NormalBriefing
      {...briefingProps}
      sources={getSourceObject()}
      cta={cta}
      onClose={() => {
        sendMessage(CLOSE_BRIEFING);
        sendMessageToParent(CLOSE_BRIEFING);
      }}
    />
  ) : null;
};
